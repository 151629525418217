import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_PICKER_STYLE,
	HIDDEN_FEATURES,
	MODULE_TYPES,
	PERIOD_BUDGET_TYPE,
	PERIODICITY,
	TASK_COST_CALCULATION_TYPE,
} from '../../constants';
import CreateProjectMutation from '../../mutations/create_project_mutation';
import CreateRetainerPeriodMutation from '../../mutations/create_retainer_period_mutation';
import DuplicateProjectMutation from '../../mutations/duplicate_project_mutation';
import CreateProjectPersonMutation from '../../mutations/create_project_person_mutation';
import CreateClientMutation from '../../mutations/create_client_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {withRouter} from 'react-router-dom';
import GenericModal from './generic_modal';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import Person from '../../forecast-app/shared/components/person/person';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createClient, createProject, duplicateProject} from '../../components/scheduling/scheduling_mutations';
import DatePickerV3 from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import Moment from 'moment';
import moment from 'moment';
import {Scrollbars} from 'react-custom-scrollbars';
import NestedDropdown from '../../forecast-app/shared/components/dropdowns/nested_dropdown';
import AddTeamMemberModalContent from './add_team_member_modal_content';
import ColorsPicker from '../../components/colors_picker';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import InputFieldV2 from '../../components/new-ui/input_field';
import NewProjectModalDetailedSettings from '../../components/new-ui/new_project_modal_detailed_settings';
import projectUtil from '../../forecast-app/shared/util/project_util';
import Info from '../../components/info';
import Nova02Image from '../../images/graphics/Nova02Image';
import * as tracking from '../../tracking';
import AssignedDropdown from '../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import {hasFeatureFlag, usingTimeApproval} from '../../forecast-app/shared/util/FeatureUtil';
import UpdateProjectMutation from '../../mutations/update_project_mutation.modern';
import {hasPermission, personIsClientUser, personIsVirtualUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {isDateDisabled} from '../../forecast-app/shared/util/DateUtil';
import {profilePicSrc, projectUrl} from '../../directApi';
import CompanySetupUtil from '../../forecast-app/shared/util/CompanySetupUtil';
import {ProjectMainInfo} from './create-new-project/ProjectMainInfo';
import {getProjectIndicatorString} from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import ValidateProjectIdMutation from '../../mutations/ValidateProjectIdMutation';

class newProjectModalV2 extends Component {
	constructor(props) {
		super(props);

		const useTimeApproval = usingTimeApproval(props.viewer.company.useTimeApproval);
		const actualPerson =
			useTimeApproval && props.viewer.company.allPersons.edges.find(p => p.node.id === props.viewer.actualPersonId);

		let projectToAddPeople = undefined;
		if (props.projectIdToAddPeople != null) {
			projectToAddPeople = props.viewer.project;
		}

		let selectedTeamMembers = [];
		if (projectToAddPeople) {
			const projectPersonIds = projectToAddPeople.projectPersons.edges.map(edge => edge.node.person.id);
			selectedTeamMembers = props.viewer.company.allPersons.edges
				.filter(edge => projectPersonIds.includes(edge.node.id))
				.map(edge => edge.node);
		} else if (actualPerson) {
			selectedTeamMembers.push(actualPerson.node);
		}
		this.state = {
			projectName: '',
			customProjectId: undefined,
			projectStartDate: Moment(),
			projectEndDate: null,
			projectLabels: [],
			useSprints: false,
			useBaseline: false,
			useDuplication: false,
			sprintLengthInDays: '',
			projectToDuplicate: null,
			duplicateResult: null,
			addAutoScheduleStep: false,
			duplicateSettings: true,
			duplicateTasks: true,
			duplicatePhases: true,
			duplicateTeam: true,
			duplicateAllocations: true,
			duplicatePlaceholders: true,
			duplicateExpenses: CompanySetupUtil.hasFinance(), // Set to false if company does not have financial module
			duplicateRetainerPeriods: CompanySetupUtil.hasFinance(), // Set to false if company does not have financial module
			duplicateColor: true,
			useManualAllocations: props.viewer.company.isUsingProjectAllocation,
			budgetType: Util.isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES)
				? BUDGET_TYPE.NON_BILLABLE
				: BUDGET_TYPE.TIME_AND_MATERIALS,
			defaultPeriodPriceAmount: null,
			defaultPeriodHoursAmount: null,
			defaultPeriodBudgetType: PERIOD_BUDGET_TYPE.FIXED_HOURS,
			defaultPeriodLength: 1,
			wrongPeriodLength: false,
			wrongPeriodAmount: false,
			defaultPeriodPeriodicity: PERIODICITY.MONTHLY,
			//defaultPeriodSettingIgnoreForBilling: false,
			defaultPeriodSettingSubtractValue: true,
			defaultPeriodSettingRollValue: true,
			//defaultPeriodSettingAddExpenses: false,
			preCreatePeriods: false,
			preCreateAmount: 1,
			periodStartDate: Moment(),
			periodStartDateHovered: null,
			client: null,
			fixedPriceBudget: null,
			autoFocusFixedPriceField: false,
			projectToAddPeople,
			addProjectPeopleMode: !!projectToAddPeople,
			addTeamMembersStep: !!projectToAddPeople,
			newProjectId: projectToAddPeople?.id,
			selectedTeamMembers,
			selectedContactPersons: actualPerson ? [actualPerson.node] : [],
			teamMemberSearch: '',
			newClientName: '',
			errorMessage: '',
			missingProjectName: false,
			missingProjectToDuplicate: false,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
			showNameValidationError: false,
			nameValidationErrorMessage: '',
			projectColor: '#bc97f5',
			settingsExpanded: false,
			rateCardSelected: this.getDefaultRateCard(),
			invalidFixedPriceFormat: false,
			invaliPeriodAmoun: false,
			createProjectPersonRequestSent: false,
		};

		this.creatingProject = false;
	}

	componentDidMount(prevProps, prevState) {
		if (this.props.projectToDuplicate != null) {
			this.setState({useDuplication: true, projectToDuplicate: this.props.projectToDuplicate, settingsExpanded: true});
		} else if (this.props.projectToDuplicateId != null) {
			const duplicatedProject = this.props.viewer.projects.edges.find(
				el => el.node.id === this.props.projectToDuplicateId
			);
			const duplicatedProjectNode = duplicatedProject && duplicatedProject.node;
			this.setState({useDuplication: true, projectToDuplicate: duplicatedProjectNode, settingsExpanded: true});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.periodStartDate !== this.state.periodStartDate ||
			prevState.preCreateAmount !== this.state.preCreateAmount ||
			prevState.defaultPeriodLength !== this.state.defaultPeriodLength ||
			prevState.defaultPeriodPeriodicity !== this.state.defaultPeriodPeriodicity
		) {
			const lastPeriodEndDate =
				this.state.projectStartDate &&
				this.state.preCreatePeriods &&
				this.state.preCreateAmount &&
				this.state.defaultPeriodLength
					? this.state.periodStartDateHovered
						? this.state.periodStartDateHovered
								.clone()
								.add(
									this.state.defaultPeriodLength * this.state.preCreateAmount,
									this.state.defaultPeriodPeriodicity === PERIODICITY.DAILY
										? 'days'
										: this.state.defaultPeriodPeriodicity === PERIODICITY.WEEKLY
										? 'weeks'
										: 'months'
								)
								.subtract(1, 'days')
						: this.state.periodStartDate
								.clone()
								.add(
									this.state.defaultPeriodLength * this.state.preCreateAmount,
									this.state.defaultPeriodPeriodicity === PERIODICITY.DAILY
										? 'days'
										: this.state.defaultPeriodPeriodicity === PERIODICITY.WEEKLY
										? 'weeks'
										: 'months'
								)
								.subtract(1, 'days')
					: null;
			if (this.state.preCreateAmount <= 0) {
				this.setState({projectEndDate: null});
			} else if (lastPeriodEndDate && lastPeriodEndDate !== this.state.projectEndDate) {
				this.setState({projectEndDate: lastPeriodEndDate});
			}
		}
	}

	handlePeriodLengthChange(newValues) {
		this.setState(newValues);
	}

	handledefaultPeriodChange(field, value) {
		this.setState({[field]: value, wrongPeriodAmount: !value || isNaN(value)});
	}

	handleNumberPeriodChange(value) {
		this.setState({preCreateAmount: value ? Math.round(value) : ''});
	}

	onNumberPeriodChangeBlur() {
		if (this.state.preCreateAmount < 1) this.setState({preCreateAmount: 1});
	}

	handleInputChange(field, value) {
		this.setState({[field]: value});
	}

	onCustomProjectIdChange(value) {
		this.handleInputChange('customProjectId', value);
		this.setState({customProjectIdError: undefined});
	}

	onCustomProjectIdBlur() {
		if (this.state.customProjectId == null || this.state.customProjectId === '') {
			this.setState({customProjectIdError: undefined});
			return;
		}
		if (!this.state.customProjectId.match(/^[a-z0-9-]+$/i)) {
			this.setState({
				customProjectIdError: this.props.intl.formatMessage({id: 'new_project_modal.project_id_error.alphanumeric'}),
			});
			return;
		}
		Util.CommitMutation(ValidateProjectIdMutation, {projectId: this.state.customProjectId}, result => {
			if (result.validateProjectId.valid === 'true') {
				trackEvent('Custom Project Id', 'Changed', {value: this.state.customProjectId});
				this.setState({customProjectIdError: undefined});
			} else {
				this.setState({
					customProjectIdError: this.props.intl.formatMessage({
						id: 'new_project_modal.project_id_error.already_in_use',
					}),
				});
			}
		});
	}

	handleCheckboxChange(value, field) {
		this.setState({[field]: value});
	}

	handleProjectSelectChange(selectedProject) {
		if (selectedProject === null) {
			this.setState({projectToDuplicate: null, useDuplication: false, missingProjectToDuplicate: true});
		} else {
			const projectToDuplicate = this.props.viewer.projects.edges.find(el => el.node.id === selectedProject.value);
			const duplicatedProjectNode = projectToDuplicate && projectToDuplicate.node;
			this.setState({projectToDuplicate: duplicatedProjectNode, useDuplication: true, missingProjectToDuplicate: false});
		}
	}

	updateLabels(labels) {
		this.setState({projectLabels: labels});
	}

	handleSettingsChange() {
		this.setState({duplicateSettings: !this.state.duplicateSettings});
	}

	handleTasksChange() {
		this.setState({duplicateTasks: !this.state.duplicateTasks});
	}

	handlePhasesChange() {
		this.setState({duplicatePhases: !this.state.duplicatePhases});
	}

	handleTeamChange() {
		this.setState({duplicateTeam: !this.state.duplicateTeam});
	}

	handleAllocationsChange() {
		this.setState({duplicateAllocations: !this.state.duplicateAllocations});
	}

	handlePlaceholdersChange() {
		this.setState({duplicatePlaceholders: !this.state.duplicatePlaceholders});
	}

	handleExpensesChange() {
		this.setState({duplicateExpenses: !this.state.duplicateExpenses});
	}

	handleRetainerPeriodsChange() {
		this.setState({duplicateRetainerPeriods: !this.state.duplicateRetainerPeriods});
	}

	handleColorChange() {
		this.setState({duplicateColor: !this.state.duplicateColor});
	}

	handleFixedPriceBudget(fixedPriceBudget) {
		this.setState({fixedPriceBudget, invalidFixedPriceFormat: !parseFloat(fixedPriceBudget)});
	}

	handleDefaultSettingChange(property) {
		this.setState({[property]: !this.state[property]});
	}

	handlePreCreatePeriodsChange() {
		this.setState({preCreatePeriods: !this.state.preCreatePeriods});
	}

	createNewLabel(labelName, callback) {}

	handleSprintLengthChange(value) {
		this.setState({sprintLengthInDays: projectUtil.handleSprintLengthChange(value)});
	}

	createProject() {
		if (!this.state.wrongPeriodAmount) {
			if (
				!this.state.projectName ||
				this.state.projectName === '' ||
				(this.state.useDuplication && !this.state.projectToDuplicate)
			) {
				let missingName = false;
				if (!this.state.projectName || this.state.projectName === '') {
					this.newProjectModal.scrollableContainer.scrollToTop();
					missingName = true;
				}
				const missingProject = this.state.useDuplication && !this.state.projectToDuplicate;

				this.setState({
					missingProjectName: missingName,
					missingProjectToDuplicate: missingProject,
					errorMessage: this.props.intl.formatMessage({id: 'common.required_field'}),
				});
			} else if (this.state.budgetType === BUDGET_TYPE.RETAINER && this.state.defaultPeriodLength < 1) {
				this.setState({
					wrongPeriodLength: true,
					errorMessage: this.props.intl.formatMessage({id: 'retainer.period_lenght_error'}),
				});
			} else if (
				this.state.budgetType === BUDGET_TYPE.RETAINER &&
				!(this.state.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS) &&
				(!this.state.defaultPeriodHoursAmount || isNaN(this.state.defaultPeriodHoursAmount)) &&
				(!this.state.defaultPeriodPriceAmount || isNaN(this.state.defaultPeriodPriceAmount))
			) {
				this.setState({wrongPeriodAmount: true});
			} else if (
				((this.state.budgetType === BUDGET_TYPE.FIXED_PRICE || this.state.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
					!this.state.fixedPriceBudget) ||
				(this.state.fixedPriceBudget && !parseFloat(this.state.fixedPriceBudget))
			) {
				this.setState({invalidFixedPriceFormat: true});
			} else if (this.state.customProjectIdError) {
				return;
			} else if (!this.creatingProject) {
				this.creatingProject = true;
				const onSuccess = result => {
					const errors = result.createProject.errors;
					if (Util.checkForSageErrorAndShowModal(errors)) {
						this.creatingProject = false;
						return;
					}
					if (Util.isScheduling()) {
						Util.dispatchScheduleEvent(result);
					}
					if (this.props.onCreateCallback) {
						setTimeout(() => {
							/* Not the proudest of hacks, but since we are planning on sunsetting the project service I did not want to make it more complicated.
							 * We have a race condition:
							 *  - project is created in service company and a project create event is dispatched
							 *  - after project creation we directly invoke the project service to connect the project to the program
							 *  - the processing of the create event and the assignProjectToProgram call overlap and both
							 *    create a new project in the project service one of them will fail with a duplicate project error
							 *
							 *  we cannot create the project in the project service without creating it in service company (it needs a service company id)
							 *  we cannot attach the project in the project service event handler, since we need it to be done in a mutation so the graph is
							 *  updated.
							 *  If we could defer the event generation until after the project has been created and attached the problem would be solved.
							 *  But controlling the event order from the frontend is not very pretty either.
							 * */
							this.props.onCreateCallback(result.createProject.project.node, this.props.closeModal);
						}, 500);
					} else if (!this.state.useDuplication || !this.state.duplicateTeam) {
						this.setState({
							errorMessage: '',
							missingProjectName: false,
							missingProjectToDuplicate: false,
							addTeamMembersStep: true,
							newProjectId: result.createProject.project.node.id,
							newProjectCompanyProjectId: result.createProject.project.node.companyProjectId,
							newProjectCustomProjectId: result.createProject.project.node.customProjectId,
							invalidFixedPriceFormat: false,
						});
						if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
							const startDate = this.state.periodStartDate;

							const periodMutationObject = {
								name: this.props.intl.formatMessage({id: 'retainer.new_period'}),
								projectId: result.createProject.project.node.id,
							};

							if (startDate) {
								periodMutationObject.startDay = startDate.date();
								periodMutationObject.startMonth = startDate.month() + 1;
								periodMutationObject.startYear = startDate.year();
							}

							if (this.state.preCreatePeriods) {
								periodMutationObject.preCreateAmount = this.state.preCreateAmount;
							}

							Util.CommitMutation(CreateRetainerPeriodMutation, periodMutationObject);
						}
					} else {
						if (!Util.isScheduling()) {
							if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
								this.props.history.push(
									projectUrl(
										result.createProject.project.node.companyProjectId,
										result.createProject.project.node.customProjectId
									) + '/retainer-tracking'
								);
							} else {
								this.props.history.push(
									projectUrl(
										result.createProject.project.node.companyProjectId,
										result.createProject.project.node.customProjectId
									) + '/scoping'
								);
							}
						}
						this.props.closeModal();
					}
					if (!hasFeatureFlag('custom_project_ids')) {
						createToast({
							duration: 5000,
							message: this.props.intl.formatMessage({id: 'new_project.project-created'}),
						});
					}
				};
				const onSuccessDuplicate = result => {
					const errors = result.duplicateProject.errors;
					if (Util.checkForSageErrorAndShowModal(errors)) {
						this.creatingProject = false;
						return;
					}
					this.setState({
						duplicateResult: result,
					});
					const duplicatedProject = this.props.viewer.projects.edges.find(
						el => el.node.id === this.state.projectToDuplicate.id
					);
					if (
						hasFeatureFlag('auto_schedule_access') &&
						!duplicatedProject.node.useManualAllocations &&
						hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE)
					) {
						this.setState({
							addAutoScheduleStep: true,
						});
					} else {
						this.endProjectDuplication();
					}
				};
				const projectLabels = [];
				for (const label of this.state.projectLabels) {
					let isValid = true;
					try {
						atob(label).includes('Label');
					} catch (e) {
						isValid = false;
					}
					if (isValid && atob(label).includes('Label')) {
						projectLabels.push(label);
					}
				}
				const mutationObject = {
					name: this.state.projectName,
					customProjectId: hasFeatureFlag('custom_project_ids') ? this.state.customProjectId : undefined,
					labels: projectLabels,
					projectColor: this.state.useDuplication && this.state.duplicateColor ? undefined : this.state.projectColor,
					rateCardId: this.state.rateCardSelected.value,
				};
				if (this.state.projectStartDate) {
					mutationObject.projectStartDay = this.state.projectStartDate.date();
					mutationObject.projectStartMonth = this.state.projectStartDate.month() + 1;
					mutationObject.projectStartYear = this.state.projectStartDate.year();
				}
				if (this.state.projectEndDate) {
					mutationObject.projectEndDay = this.state.projectEndDate.date();
					mutationObject.projectEndMonth = this.state.projectEndDate.month() + 1;
					mutationObject.projectEndYear = this.state.projectEndDate.year();
				}
				if (this.state.client) {
					mutationObject.clientId = this.state.client;
				}
				mutationObject.useManualAllocations = this.state.useManualAllocations;

				mutationObject.budget = +this.state.fixedPriceBudget || 0;

				if (this.state.useDuplication) {
					mutationObject.projectId = this.state.projectToDuplicate.id;
					mutationObject.duplicateSettings = this.state.duplicateSettings;
					mutationObject.duplicatePhases = this.state.duplicatePhases;
					mutationObject.duplicateTasks = this.state.duplicateTasks;
					mutationObject.duplicateProjectPersons = this.state.duplicateTeam;
					mutationObject.duplicateAllocations = this.state.duplicateAllocations;
					mutationObject.duplicateExpenses = this.state.duplicateExpenses;
					mutationObject.duplicateRetainerPeriods = this.state.duplicateRetainerPeriods;
					mutationObject.duplicateColor = this.state.duplicateColor;
					mutationObject.useManualAllocations = undefined;

					if (hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS)) {
						mutationObject.duplicatePlaceholders = this.state.duplicatePlaceholders;
						mutationObject.duplicatePlaceholderAllocations = this.state.duplicatePlaceholders;
					}

					if (Util.isScheduling()) {
						mutationObject.csrfToken = Util.getCsrfValue();
						mutationObject.socketClientId = Util.getClientId();
						mutationObject.labels = undefined;
						mutationObject.rateCardId = undefined;
						duplicateProject(mutationObject, onSuccessDuplicate);
					} else {
						Util.CommitMutation(DuplicateProjectMutation, mutationObject, onSuccessDuplicate);
					}
				} else {
					// Set use baseline
					if (this.state.useBaseline && this.state.budgetType !== BUDGET_TYPE.RETAINER) {
						mutationObject.useBaseline = this.state.useBaseline;
					}

					mutationObject.budgetType = this.state.budgetType;
					if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
						mutationObject.defaultPeriodPriceAmount =
							this.state.defaultPeriodPriceAmount < 0 ? 0 : +this.state.defaultPeriodPriceAmount || 0;
						mutationObject.defaultPeriodHoursAmount =
							this.state.defaultPeriodHoursAmount < 0 ? 0 : +this.state.defaultPeriodHoursAmount || 0;
						mutationObject.defaultPeriodBudgetType = this.state.defaultPeriodBudgetType;
						mutationObject.defaultPeriodLength = this.state.defaultPeriodLength;
						mutationObject.defaultPeriodPeriodicity = this.state.defaultPeriodPeriodicity;
					}

					if (this.state.useSprints) {
						mutationObject.sprintTimeBox = this.state.useSprints;
						mutationObject.sprintLengthInDays = this.state.sprintLengthInDays ? this.state.sprintLengthInDays : 14;
					}

					if (Util.isScheduling()) {
						mutationObject.csrfToken = Util.getCsrfValue();
						mutationObject.socketClientId = Util.getClientId();
						createProject(mutationObject, onSuccess);
					} else {
						Util.CommitMutation(CreateProjectMutation, mutationObject, onSuccess);
					}
				}
			}
		}
	}

	navigateToNewProject() {
		this.props.closeModal();
		if (Util.isScheduling()) {
			return;
		}
		if (this.state.budgetType === BUDGET_TYPE.RETAINER) {
			this.props.history.push(
				projectUrl(this.state.newProjectCompanyProjectId, this.state.newProjectCustomProjectId) + '/retainer-tracking'
			);
		} else if (this.state.useBaseline) {
			this.props.history.push(
				projectUrl(this.state.newProjectCompanyProjectId, this.state.newProjectCustomProjectId) + '/baseline'
			);
		} else {
			this.props.history.push(
				projectUrl(this.state.newProjectCompanyProjectId, this.state.newProjectCustomProjectId) + '/scoping'
			);
		}
	}

	onClientSelect(selected) {
		this.setState({client: selected ? selected.value : null});
	}

	onDateRangeChange(startDate, endDate) {
		this.setState({projectDatesChanged: true, projectStartDate: startDate, projectEndDate: endDate});
	}

	onDateRangeChangeIntervalObject(interval) {
		this.setState({
			projectDatesChanged: true,
			projectStartDate: Moment(interval.startDate),
			projectEndDate: Moment(interval.endDate),
		});
	}

	clearBothDates() {
		this.setState({projectStartDate: null, projectEndDate: null, preCreatePeriods: false});
	}

	onProjectTypeChange(useManualAllocations) {
		if (useManualAllocations !== this.state.useManualAllocations) {
			this.setState({useManualAllocations: useManualAllocations});
		}
	}

	onBudgetTypeChange(newValues) {
		this.setState(newValues);
	}

	onContactSelect(personIds) {
		const selectedTeamMembers = this.state.selectedTeamMembers;
		const selectedTeamMembersIds = selectedTeamMembers.map(person => person.id);

		const selectedContactPersons = this.state.selectedContactPersons;
		const selectedContactPersonsIds = selectedContactPersons.map(person => person.id);

		const personId = personIds.length > 0 && personIds[0];

		if (personId) {
			const person = this.props.viewer.company.allPersons.edges.find(p => p.node.id === personId);
			if (!selectedContactPersonsIds.includes(personId)) {
				selectedContactPersons.push(person.node);
			}
			if (!selectedTeamMembersIds.includes(personId)) {
				selectedTeamMembers.push(person.node);
			}

			this.setState({selectedTeamMembers, selectedContactPersons});
		}
	}

	unassignContact(personId) {
		const selectedContactPersons = this.state.selectedContactPersons;
		let filteredAssigned = selectedContactPersons.filter(personEdge => personEdge.id !== personId);
		this.setState({selectedContactPersons: filteredAssigned});
	}

	onTeamSelect(selected) {
		const selectedTeamMembers = this.state.selectedTeamMembers;
		const selectedTeamMembersIds = selectedTeamMembers.map(person => person.id);
		if (selected.person) {
			if (!selectedTeamMembersIds.includes(selected.person.id)) {
				selectedTeamMembers.push(selected.person);
			}
		} else {
			const selectedTeam = this.props.viewer.company.teams.edges.find(team => team.node.id === selected.value);
			const selectedTemPersonIds = selectedTeam.node.teamPersons.edges.map(tp => tp.node.person.id);
			if (selectedTeam) {
				this.props.viewer.company.allPersons.edges
					.filter(person => !personIsClientUser(person.node))
					.forEach(person => {
						if (selectedTemPersonIds.includes(person.node.id) && !selectedTeamMembersIds.includes(person.node.id)) {
							selectedTeamMembers.push(person.node);
						}
					});
			}
		}
		this.setState({selectedTeamMembers});
	}

	removeFromSelectedTeamMembers(person) {
		const selectedTeamMembers = this.state.selectedTeamMembers.filter(p => p.id !== person.id);
		const selectedContactPersons = this.state.selectedContactPersons.filter(p => p.id !== person.id);
		this.setState({selectedTeamMembers, selectedContactPersons});
	}

	addPeopleToProject() {
		const selectedTeamMembersIds = this.state.selectedTeamMembers.map(person => person.id);
		const selectedContactIds = this.state.selectedContactPersons.map(person => person.id);
		let projectId = this.state.newProjectId;
		let projectGroupId = null;
		if (this.state.addProjectPeopleMode) {
			const isConnectedProject = !!this.state.projectToAddPeople.projectGroupId;
			projectId = isConnectedProject ? null : this.state.projectToAddPeople.id;
			projectGroupId = isConnectedProject ? this.state.projectToAddPeople.projectGroupId : null;
		}
		const showAllocationModal = personIds => {
			// only if project allocations show allocations modal
			if (this.state.useManualAllocations || this.props.viewer.company.isUsingMixedAllocation) {
				showModal(
					{
						type: MODAL_TYPE.ALLOCATE_TEAM_MEMBERSV2,
						projectId,
						projectGroupId,
						selectedTeamMembersIds: personIds,
						closeModal: () => this.navigateToNewProject(),
					},
					true
				);
			} else {
				this.props.closeModal();

				if (!Util.isScheduling()) {
					this.navigateToNewProject();
				}
			}
		};

		if (!this.state.createProjectPersonRequestSent) {
			this.setState({createProjectPersonRequestSent: true}); // only allow sending a single createProjectPerson request from the modal to avoid duplicates

			if (this.state.addProjectPeopleMode) {
				const originalSelectedProjectPersonIds = this.props.viewer.project.projectPersons.edges.map(
					edge => edge.node.person.id
				);
				const addedPersonIds = selectedTeamMembersIds.filter(
					personId => !originalSelectedProjectPersonIds.includes(personId)
				);
				if (addedPersonIds) {
					Util.CommitMutation(
						CreateProjectPersonMutation,
						{
							projectId,
							projectGroupId,
							personIds: addedPersonIds,
						},
						response => {
							Util.dispatchScheduleEvent(response);
							showAllocationModal(addedPersonIds);
						}
					);
				}
			} else if (selectedTeamMembersIds.length !== 0) {
				Util.CommitMutation(
					CreateProjectPersonMutation,
					{
						projectId: this.state.newProjectId,
						personIds: selectedTeamMembersIds,
						contactIds: selectedContactIds,
					},
					response => {
						if (Util.isScheduling()) {
							Util.dispatchScheduleEvent(response);
						}
						// Change to project cost calculation if using baseline and assigning anybody
						if (this.state.useBaseline) {
							Util.CommitMutation(
								UpdateProjectMutation,
								{
									id: this.state.newProjectId,
									taskCostCalculationType: TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS,
								},
								() => showAllocationModal(selectedTeamMembersIds)
							);
						} else {
							showAllocationModal(selectedTeamMembersIds);
						}
					}
				);
				// Return because be want to wait until the CreateProjectPersonMutation is finished before navigating to the new project.
				return;
			} else {
				this.props.closeModal();
			}
		}
		if (Util.isScheduling()) return;
		this.navigateToNewProject();
	}

	onNewClientNameChange(value) {
		this.setState({newClientName: value});
	}

	onAddNewClient() {
		const onSuccess = res => {
			if (Util.isScheduling()) {
				Util.dispatchScheduleEvent(res);
				this.props.viewer.company.clients.edges.push({
					node: {id: res.createClient.client.node.id, name: res.createClient.client.node.name},
				});
			}
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'settings_clients.client-added'})});
			this.setState({showAddClientControls: false, client: res.createClient.client.node.id});
		};
		const mutationObject = {
			companyId: this.props.viewer.company.id,
			name: this.state.newClientName,
		};
		if (Util.isScheduling()) {
			mutationObject.companyId = undefined;
			createClient(mutationObject, onSuccess);
		} else {
			Util.CommitMutation(CreateClientMutation, mutationObject, onSuccess);
		}
	}

	onPeriodicityChange(periodicity) {
		if (periodicity !== this.state.defaultPeriodPeriodicity) {
			this.setState({defaultPeriodPeriodicity: periodicity.value});
		}
	}

	onPeriodBudgetTypeChange(periodBudgetType) {
		if (periodBudgetType !== this.state.defaultPeriodBudgetType) {
			this.setState({defaultPeriodBudgetType: periodBudgetType[0]});
		}
	}

	handleProjectNameBlur() {
		if (this.state.projectName && this.state.projectName !== '') {
			this.setState({errorMessage: '', missingProjectName: false});
		}
	}

	toggleNewClientControls() {
		this.setState(prevState => {
			return {showAddClientControls: !prevState.showAddClientControls};
		});
	}

	handleSelectColorChange(color) {
		this.setState({
			projectColor: color,
		});
	}

	toggleMoreSettings() {
		this.setState({
			settingsExpanded: !this.state.settingsExpanded,
		});
	}

	getDefaultRateCard() {
		const {formatMessage} = this.props.intl;
		return projectUtil.getDefaultRateCard(
			formatMessage,
			this.props.viewer.company.defaultRateCard,
			this.props.viewer.company.currency
		);
	}

	handleSelectRateChange(rate) {
		this.setState({
			rateCardSelected: rate,
		});
	}

	changePeriodStartDate(start) {
		this.setState({
			periodStartDate: start,
			projectStartDate: start,
			periodStartDateHovered: null,
		});
	}

	setHoveredDay(day) {
		this.setState({
			periodStartDateHovered: day,
		});
	}

	endProjectDuplication() {
		if (Util.isScheduling()) {
			Util.dispatchScheduleEvent(this.state.duplicateResult);
		} else {
			this.props.history.push(
				projectUrl(
					this.state.duplicateResult.duplicateProject.project.node.companyProjectId,
					this.state.duplicateResult.duplicateProject.project.node.customProjectId
				) + '/settings'
			);
		}
		this.props.closeModal();
	}

	getNewProjectModalContent(duplicateOptions) {
		const {formatMessage} = this.props.intl;
		const options_clients = this.props.viewer.company.clients.edges
			.map(client => ({value: client.node.id, label: client.node.name}))
			.sort((a, b) => Util.sortAlphabetically(a.label, b.label));
		options_clients.splice(0, 0, {value: null, label: formatMessage({id: 'client_selector.no_client_text'})});
		const createdAt = moment(this.props.viewer.company.createdAt, 'x');
		const transitionDay = moment('2021-03-24'); //from this day people can choose a custom color in the project settings

		return (
			<div className="new-project-content-wrapper-v2" data-cy={'new-project-modal'}>
				{hasFeatureFlag('custom_project_ids') ? (
					<ProjectMainInfo
						label={formatMessage({id: 'common.project-name'})}
						onProjectNameChange={this.handleInputChange.bind(this, 'projectName')}
						onProjectNameBlur={this.handleProjectNameBlur.bind(this)}
						projectName={this.state.projectName}
						invalidProjectName={this.state.missingProjectName}
						projectNameErrorMessage={this.state.errorMessage}
						/**/
						customProjectId={this.state.customProjectId}
						defaultCustomProjectId={this.state.defaultCustomProjectId}
						onCustomProjectIdChange={this.onCustomProjectIdChange.bind(this)}
						onCustomProjectIdBlur={this.onCustomProjectIdBlur.bind(this)}
						customProjectIdError={this.state.customProjectIdError}
						/**/
						projectDatesChanged={this.state.projectDatesChanged}
						useDuplication={this.state.useDuplication}
						isProjectLocked={this.props.isProjectLocked}
						clearBothDates={this.clearBothDates.bind(this)}
						startDate={this.state.projectStartDate?.format('YYYY-MM-DD')}
						endDate={this.state.projectEndDate?.format('YYYY-MM-DD')}
						handleDateRangeChange={this.onDateRangeChangeIntervalObject.bind(this)}
						/**/
						clientOptions={options_clients}
						onClientChange={this.onClientSelect.bind(this)}
						client={this.state.client}
						/**/
						duplicateColor={this.state.duplicateColor}
						projectColor={this.state.projectColor}
						onColorSelect={this.handleSelectColorChange.bind(this)}
						isOldCompany={createdAt.isBefore(transitionDay)}
						lastCompanyProjectId={this.props.viewer.company.projectLastSequence}
					/>
				) : (
					<>
						<div className={'project-main-info'}>
							<div className={'project-name'}>
								<InputFieldV2
									id={'project-title'}
									label={formatMessage({id: 'common.project-name'})}
									type="text"
									onChange={this.handleInputChange.bind(this, 'projectName')}
									onBlur={this.handleProjectNameBlur.bind(this)}
									value={this.state.projectName}
									placeholder={formatMessage({id: 'new_project_modal.project_name_placeholder'})}
									required={true}
									autoFocus={true}
									invalidInput={this.state.missingProjectName}
									errorMessage={this.state.errorMessage}
									maxLength={191}
									cy={'new-project-name'}
								/>
							</div>
							<div className={'project-dates'}>
								<label className={'date-label'}>{formatMessage({id: 'common.dates'})}</label>
								{this.state.projectDatesChanged && this.state.useDuplication ? (
									<TooltipContainer
										showOnRender={true}
										tooltipInfinteDuration={true}
										triangleLocation={'topMiddle'}
										infoText={[
											{
												title: ['project_settings.changing_duplicated_dates'],
												details: ['project_settings.duplication_date_info'],
											},
										]}
									>
										<DatePickerV3
											disabled={this.props.isProjectLocked}
											clearBothDates={this.clearBothDates.bind(this)}
											isNewDateRange={true}
											startDate={this.state.projectStartDate}
											endDate={this.state.projectEndDate}
											handleDateRangeChange={this.onDateRangeChange.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.STANDARD}
											clearable={true}
											buttonCy={'new-project-dates'}
											disabledDates={isDateDisabled}
										/>
									</TooltipContainer>
								) : (
									<DatePickerV3
										disabled={this.props.isProjectLocked}
										clearBothDates={this.clearBothDates.bind(this)}
										isNewDateRange={true}
										startDate={this.state.projectStartDate}
										endDate={this.state.projectEndDate}
										handleDateRangeChange={this.onDateRangeChange.bind(this)}
										datePickerStyle={DATE_PICKER_STYLE.STANDARD}
										clearable={true}
										buttonCy={'new-project-dates'}
										disabledDates={isDateDisabled}
									/>
								)}
							</div>
							{this.state.useDuplication && this.state.duplicateColor ? null : (
								<div className={'color-picker'}>
									<label className={'color-picker-label'}>
										{formatMessage({id: 'project_settings.project-color'})}
									</label>
									<ColorsPicker
										value={this.state.projectColor}
										rectangle={true}
										onColorSelect={this.handleSelectColorChange.bind(this)}
									/>
								</div>
							)}
						</div>
						{!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT) ? (
							<div className="project-second-info">
								<div
									className={'add-client-controls' + (this.state.showAddClientControls ? ' new-client' : '')}
								>
									<DropdownV2
										customHeight={30}
										customWidth={282}
										key={'client-dropdown'}
										options={options_clients}
										onChange={this.onClientSelect.bind(this)}
										clearable={true}
										value={this.state.client}
										label={formatMessage({id: 'common.client'})}
										onCreateNew={this.onAddNewClient.bind(this)}
										createNewText={formatMessage({id: 'create-project-modal.create-client'})}
										onSearchValueChange={this.onNewClientNameChange.bind(this)}
										placeholder={
											this.state.showAddClientControls
												? ''
												: formatMessage({id: 'empty_state_project_client.select-placeholder'})
										}
									/>
								</div>
							</div>
						) : null}
					</>
				)}

				<div className={'separation'}>
					<div className={'separation-line'} />
				</div>
				<div className={'more-settings'} data-cy={'more-settings'} onClick={this.toggleMoreSettings.bind(this)}>
					<div data-userpilot={'more-settings'}>
						{this.state.settingsExpanded
							? formatMessage({id: 'project_settings.fewer_settings'})
							: formatMessage({id: 'project_settings.more_settings'})}
					</div>
					<div className={'arrow' + (this.state.settingsExpanded ? ' expanded' : '')} />
				</div>

				{this.state.settingsExpanded ? (
					<NewProjectModalDetailedSettings
						modules={this.props.viewer.company.modules}
						useSprints={this.state.useSprints}
						useBaseline={this.state.useBaseline}
						projectToDuplicate={this.state.projectToDuplicate}
						missingProjectToDuplicate={this.state.missingProjectToDuplicate}
						errorMessage={this.state.errorMessage}
						sprintLengthInDays={this.state.sprintLengthInDays}
						handleSprintLengthChange={this.handleSprintLengthChange.bind(this)}
						useDuplication={this.state.useDuplication}
						labels={this.props.viewer.company.labels.edges.map(edge => edge.node)}
						handleCheckboxChange={this.handleCheckboxChange.bind(this)}
						handleSettingsChange={this.handleSettingsChange.bind(this)}
						handleTasksChange={this.handleTasksChange.bind(this)}
						handlePhasesChange={this.handlePhasesChange.bind(this)}
						handleTeamChange={this.handleTeamChange.bind(this)}
						handleAllocationsChange={this.handleAllocationsChange.bind(this)}
						handlePlaceholdersChange={this.handlePlaceholdersChange.bind(this)}
						handleExpensesChange={this.handleExpensesChange.bind(this)}
						handleRetainerPeriodsChange={this.handleRetainerPeriodsChange.bind(this)}
						handleColorChange={this.handleColorChange.bind(this)}
						duplicateSettings={this.state.duplicateSettings}
						duplicateTasks={this.state.duplicateTasks}
						duplicatePhases={this.state.duplicatePhases}
						duplicateTeam={this.state.duplicateTeam}
						duplicateAllocations={this.state.duplicateAllocations}
						duplicatePlaceholders={this.state.duplicatePlaceholders}
						duplicateExpenses={this.state.duplicateExpenses}
						duplicateRetainerPeriods={this.state.duplicateRetainerPeriods}
						duplicateColor={this.state.duplicateColor}
						useManualAllocations={this.state.useManualAllocations}
						onProjectTypeChange={this.onProjectTypeChange.bind(this)}
						budgetType={this.state.budgetType}
						onBudgetTypeChange={this.onBudgetTypeChange.bind(this)}
						handleFixedPriceBudget={this.handleFixedPriceBudget.bind(this)}
						invalidFixedPriceFormat={this.state.invalidFixedPriceFormat}
						rateCardSelected={this.state.rateCardSelected}
						companyCurrency={this.props.viewer.company.currency}
						fixedPriceBudget={this.state.fixedPriceBudget}
						handleSelectRateChange={this.handleSelectRateChange.bind(this)}
						rateCards={this.props.viewer.company.rateCards.edges}
						onPeriodicityChange={this.onPeriodicityChange.bind(this)}
						defaultPeriodPeriodicity={this.state.defaultPeriodPeriodicity}
						wrongPeriodLength={this.state.wrongPeriodLength}
						wrongPeriodAmount={this.state.wrongPeriodAmount}
						defaultPeriodLength={this.state.defaultPeriodLength}
						defaultPeriodBudgetType={this.state.defaultPeriodBudgetType}
						onPeriodBudgetTypeChange={this.onPeriodBudgetTypeChange.bind(this)}
						handlePeriodLengthChange={this.handlePeriodLengthChange.bind(this)}
						handledefaultPeriodChange={this.handledefaultPeriodChange.bind(this)}
						defaultPeriodHoursAmount={this.state.defaultPeriodHoursAmount}
						defaultPeriodPriceAmount={this.state.defaultPeriodPriceAmount}
						projectStartDate={this.state.projectStartDate}
						preCreatePeriods={this.state.preCreatePeriods}
						handleNumberPeriodChange={this.handleNumberPeriodChange.bind(this)}
						onNumberPeriodChangeBlur={this.onNumberPeriodChangeBlur.bind(this)}
						preCreateAmount={this.state.preCreateAmount}
						periodStartDate={this.state.periodStartDate}
						duplicateOptions={duplicateOptions}
						periodStartDateHovered={this.state.periodStartDateHovered}
						handleProjectSelectChange={this.handleProjectSelectChange.bind(this)}
						createNewLabel={this.createNewLabel.bind(this)}
						updateLabels={this.updateLabels.bind(this)}
						setHoveredDay={this.setHoveredDay.bind(this)}
						changePeriodStartDate={this.changePeriodStartDate.bind(this)}
						projectLabels={this.state.projectLabels}
						availableFeatureFlags={this.props.viewer.availableFeatureFlags}
						tier={this.props.viewer.company.tier}
						isUsingProjectAllocation={this.state.useManualAllocations}
						isUsingMixedAllocation={this.props.viewer.company.isUsingMixedAllocation}
					/>
				) : (
					<div className={'project-third-info empty'} />
				)}
			</div>
		);
	}

	personInvited(person) {
		this.state.selectedTeamMembers.push(person);
		this.setState({selectedTeamMembers: this.state.selectedTeamMembers});
	}

	// V3 chargebeee & seat based modal - THIS IS ONLY WITH CHARGEBEE PLANS
	getV3AddPeopleToProjectContent() {
		const {formatMessage} = this.props.intl;
		const useTimeApproval = usingTimeApproval(this.props.viewer.company.useTimeApproval);
		const selectedTeamMembersIds = this.state.selectedTeamMembers.map(person => person.id);
		const selectedContactPersonsIds = this.state.selectedContactPersons.map(person => person.id);
		const team_member_options = this.props.viewer.company.allPersons.edges
			.filter(person => !selectedTeamMembersIds.includes(person.node.id) && !personIsClientUser(person.node))
			.map(person => ({value: person.node.id, label: person.node.fullName, person: person.node}));
		const team_options = this.props.viewer.company.teams.edges
			.filter(
				team =>
					team.node.teamPersons.edges.filter(tp => !selectedTeamMembersIds.includes(tp.node.person.id)).length !== 0
			)
			.map(team => ({value: team.node.id, label: team.node.name}));

		const options = [
			{value: 'teams', label: formatMessage({id: 'settings_team.teams_section_title'}), nestedOptions: team_options},
			{
				value: 'team-members',
				isPersonSelector: true,
				label: formatMessage({id: 'settings.team-members'}),
				nestedOptions: team_member_options,
			},
		];

		const contact_options = this.props.viewer.company.allPersons.edges
			.filter(
				person =>
					!selectedContactPersonsIds.includes(person.node.id) &&
					!personIsClientUser(person.node) &&
					!personIsVirtualUser(person.node)
			)
			.map(p => {
				return {node: {person: p.node}};
			});

		const selectedTeamMembers = this.selectedTeamMembersToShow();

		const addTeamMembersContent = (
			<div>
				<AddTeamMemberModalContent
					projectId={this.props.projectId}
					projectGroupId={this.props.projectGroupId}
					buttonLocation={this.props.buttonLocation}
					viewer={this.props.viewer}
					personInvited={this.personInvited.bind(this)}
					closeModal={this.props.closeModal}
				/>
			</div>
		);

		return (
			<div
				className={
					'add-team-modal-v3' +
					(useTimeApproval ? ' tall' : '') +
					(this.state.showInviteTeamMemberForm ? ' wide' : '')
				}
			>
				{this.state.showInviteTeamMemberForm ? null : (
					<div className="team-select">
						{useTimeApproval && !this.state.addProjectPeopleMode ? (
							<div className={'contact-dropdown-wrapper'}>
								<div className={'dropdown-header-text'}>{formatMessage({id: 'common.project_owners'})}</div>
								<AssignedDropdown
									cy={'add-contact-member'}
									assignedPersons={this.state.selectedContactPersons}
									assignablePersons={contact_options}
									assignPerson={personId => this.onContactSelect(personId)}
									unassignPerson={this.unassignContact.bind(this)}
									customPlaceholder={'Select Project Owners'}
									maxPeopleIconsShown={10}
								/>
							</div>
						) : null}
						<div className={'dropdown-header-text'}>
							{formatMessage({id: 'common.team'}) + '/' + formatMessage({id: 'settings.team-members'})}
						</div>
						<NestedDropdown
							cy={'add-team-member'}
							key={'team-dropdown'}
							options={options}
							onChange={this.onTeamSelect.bind(this)}
							clearable={false}
							value={this.state.selectedTeam}
							placeholder={formatMessage({id: 'add_team_modal.select_team_people_placeholder'})}
							nested={true}
							stayOpenOnSelect={true}
							showArrowWhenCollapsed={true}
							maxDropdownHeight={302}
						/>
						<div className={'invite-link-section'} onClick={() => this.setState({showInviteTeamMemberForm: true})}>
							<div className={'invite-text'}>
								<span className={'green'}>{'+ Invite a Team Member'}</span>
								{' to your Forecast account'}
							</div>
						</div>
					</div>
				)}
				{this.state.showInviteTeamMemberForm ? <div className={'invite-section'}>{addTeamMembersContent}</div> : null}
				<span className="selected-count">
					{formatMessage({id: 'new_project_modal.add_team_members_count'}, {count: selectedTeamMembers.length})}
				</span>
				<div className="team-members-select">
					<Scrollbars autoHeight={true} autoHeightMin={1} autoHeightMax={259} hideTracksWhenNotNeeded={true}>
						<ul>
							{selectedTeamMembers
								? selectedTeamMembers.map((person, index) => (
										<li key={index}>
											<div
												className={
													'person-list-option' +
													(index === selectedTeamMembers.length - 1 ? ' last-option' : '')
												}
											>
												<div className="person-details-wrapper">
													<div style={{minWidth: '28px', maxWidth: '212px'}}>
														<Person
															name={`${person.firstName} ${person.lastName}`}
															showName={false}
															showRole={false}
															imageSrc={profilePicSrc(person.profilePictureId)}
															imageSize="new-ui-dropdown"
														/>
													</div>
													<div className="team-member-name-email">
														<span data-cy={'team-member-name'} className="team-member-name">
															{person.firstName + ' ' + person.lastName}
														</span>
														<span className="team-member-email">
															{person.role ? person.role.name : ''}
														</span>
													</div>
												</div>
												{this.getTeamForPerson(person) ? (
													<div className={'team-indicator'}>
														<span className={'team-indicator-text'}>
															{this.getTeamForPerson(person).node.name}
														</span>
													</div>
												) : (
													<div className={'team-indicator-placeholder'} />
												)}
												<button
													className="remove-selected-button"
													onClick={this.removeFromSelectedTeamMembers.bind(this, person)}
												/>
											</div>
										</li>
								  ))
								: null}
						</ul>
					</Scrollbars>
				</div>
			</div>
		);
	}

	selectedTeamMembersToShow() {
		let hiddenSelectedPersonIds = [];
		if (this.state.addProjectPeopleMode) {
			hiddenSelectedPersonIds = this.props.viewer.project.projectPersons.edges.map(edge => edge.node.person.id);
		}
		return this.state.selectedTeamMembers.filter(person => !hiddenSelectedPersonIds.includes(person.id));
	}

	getAutoScheduleModal() {
		const {formatMessage} = this.props.intl;

		const multiInfoMessage = [];
		multiInfoMessage.push({
			title: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_1_headline'}),
			text: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_1_text'}),
		});
		multiInfoMessage.push({
			title: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_2_headline'}),
			text: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_2_text'}),
		});
		multiInfoMessage.push({
			title: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_3_headline'}),
			text: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_3_text'}),
		});
		multiInfoMessage.push({
			title: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_4_headline'}),
			text: formatMessage({id: 'new_project_modal.auto_schedule_info_tooltip_4_text'}),
		});

		const content = (
			<div class="auto-scheduling-step-content">
				<div className="auto-scheduling-description">{formatMessage({id: 'new_project_modal.auto_schedule_text'})}</div>
				<div className="info-row">
					<div>{formatMessage({id: 'new_project_modal.auto_schedule_info'})}</div>
					<Info small={true} multiInfoMessage={multiInfoMessage} showOnTop wideToopltip />
				</div>
			</div>
		);
		return content;
	}

	getTeamForPerson(person) {
		const teams = this.props.viewer.company.teams.edges;
		const team = teams.find(team => team.node.teamPersons.edges.find(p => p.node.person.id === person.id));
		return team;
	}

	goBack() {
		this.setState({showInviteTeamMemberForm: false});
	}

	openAutoScheduling() {
		this.props.history.push(
			`${projectUrl(
				this.state.duplicateResult.duplicateProject.project.node.companyProjectId,
				this.state.duplicateResult.duplicateProject.project.node.customProjectId
			)}/schedule#auto-schedule`
		);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const duplicateOptions = this.props.viewer.projects.edges
			.filter(project => project.node.fullAccessToProject)
			.map(project => {
				if (hasFeatureFlag('custom_project_ids')) {
					return {value: project.node.id, label: project.node.customProjectId + ' ' + project.node.name};
				} else {
					return {
						value: project.node.id,
						label:
							getProjectIndicatorString(project.node.companyProjectId, project.node.customProjectId) +
							' ' +
							project.node.name,
					};
				}
			});
		const datesValid =
			!this.state.projectStartDate ||
			!this.state.projectEndDate ||
			this.state.projectStartDate <= this.state.projectEndDate;
		const buttons = this.state.addTeamMembersStep
			? [
					this.state.addProjectPeopleMode
						? {
								text: formatMessage({id: 'common.cancel'}),
								callback: this.props.closeModal,
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
						  }
						: {
								text: formatMessage({id: 'project_allocation_modal.skip'}),
								cy: 'skip-allocation-button',
								callback: this.navigateToNewProject.bind(this),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
						  },
					{
						text: this.state.addProjectPeopleMode
							? formatMessage({id: 'common.add'})
							: formatMessage({id: 'common.assign'}),
						disabled: this.state.addProjectPeopleMode && !this.selectedTeamMembersToShow().length,
						cy: 'assign-allocation-button',
						callback: this.addPeopleToProject.bind(this),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						preventDefaultClose: true,
					},
			  ]
			: [
					{
						text: formatMessage({id: 'common.cancel'}),
						callback: this.props.closeModal,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'new_project_modal.save_button'}),
						cy: 'create-project-button',
						callback: this.createProject.bind(this),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
						preventDefaultClose: true,
						disabled: !datesValid,
					},
			  ];
		if (this.state.addTeamMembersStep) {
			if (this.state.showInviteTeamMemberForm) {
				buttons.unshift({
					text: 'go back',
					cy: 'back-allocation-button',
					callback: this.goBack.bind(this),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREY,
					preventDefaultClose: true,
				});
			}
			const headerText = this.state.showInviteTeamMemberForm
				? formatMessage({id: 'new_project_modal.invite_team_members_title'})
				: this.state.addProjectPeopleMode
				? formatMessage({id: 'scheduling.add_people'})
				: formatMessage({id: 'common.assign'});
			return (
				<GenericModal
					useScrollbars={false}
					closeModal={this.props.closeModal}
					headerText={headerText}
					buttons={buttons}
					content={this.getV3AddPeopleToProjectContent()}
				/>
			);
		}
		if (this.state.addAutoScheduleStep) {
			const buttons = [
				{
					text: formatMessage({id: 'common.skip'}),
					cy: 'skip-auto-scheduling-button',
					callback: this.endProjectDuplication.bind(this),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'auto_scheduling.auto_schedule'}),
					cy: 'auto-scheduling-button',
					className: 'auto-scheduling-button',
					callback: () => {
						tracking.trackElementClicked('Auto Schedule project from new project modal');
						trackEvent('Auto Schedule', 'Opened');
						this.openAutoScheduling(this);
					},
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.AI_BLUE,
					autoScheduleStyle: true,
				},
			];
			return (
				<GenericModal
					useScrollbars={false}
					closeModal={this.props.closeModal}
					headerText={formatMessage({id: 'auto_scheduling.auto_schedule'})}
					buttons={buttons}
					content={this.getAutoScheduleModal()}
					coverImage={<Nova02Image />}
					className="auto-scheduling-step"
				/>
			);
		}

		return (
			<GenericModal
				ref={e => (this.newProjectModal = e)}
				useScrollbars={true}
				closeModal={this.props.closeModal}
				headerText={formatMessage({id: 'new_project.create_new_project'})}
				buttons={buttons}
				content={this.getNewProjectModalContent(duplicateOptions)}
			/>
		);
	}
}

const newProjectModalV2Query = graphql`
	query newProjectModalV2_Query($projectId: ID, $fetchProject: Boolean!) {
		viewer {
			actualPersonId
			component(name: "new_project_modal")
			...newProjectModalV2_viewer @arguments(projectId: $projectId, fetchProject: $fetchProject)
		}
	}
`;

export {newProjectModalV2Query};

export default injectIntl(
	withRouter(
		createFragmentContainer(newProjectModalV2, {
			viewer: graphql`
				fragment newProjectModalV2_viewer on Viewer
				@argumentDefinitions(projectId: {type: "ID"}, fetchProject: {type: "Boolean!"}) {
					language
					id
					actualPersonId
					availableFeatureFlags {
						key
					}
					projects(first: 10000) {
						edges {
							node {
								id
								name
								companyProjectId
								customProjectId
								useManualAllocations
								useBaseline
								fullAccessToProject
							}
						}
					}
					project(internalId: $projectId) @include(if: $fetchProject) {
						id
						projectGroupId
						projectPersons(first: 1000) {
							edges {
								node {
									id
									person {
										id
									}
								}
							}
						}
					}
					company {
						id
						modules {
							moduleType
						}
						profiles(first: 10000) @connection(key: "Company_profiles", filters: []) {
							edges {
								node {
									id
									name
									canBeEdited
									personsCount
									permissions
									disabled
								}
							}
						}
						currency
						createdAt
						userSeats
						virtualSeats
						tier
						isChargebeeCustomer
						isUsingProjectAllocation
						isUsingMixedAllocation
						oktaEnabled
						oneloginEnabled
						AADEnabled
						useTimeApproval
						isCoreTier
						projectLastSequence
						labels(first: 10000, labelType: PROJECT) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
								}
							}
						}
						clients(first: 10000) @connection(key: "Company_clients") {
							edges {
								node {
									id
									name
								}
							}
						}
						allPersons(first: 10000, onlyActive: true) @connection(key: "Company_modal_allPersons", filters: []) {
							edges {
								node {
									id
									active
									role {
										id
										name
									}
									fullName
									firstName
									lastName
									email
									permissions
									client {
										id
									}
									profilePictureId
									profilePictureDefaultId
								}
							}
						}
						teams(first: 1000) {
							edges {
								node {
									id
									name
									teamPersons(first: 1000) {
										edges {
											node {
												id
												person {
													id
												}
											}
										}
									}
								}
							}
						}
						roles(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						defaultRateCard {
							id
							name
							currency
						}
						rateCards(first: 10000) {
							edges {
								node {
									id
									name
									defaultRate
									currency
									parentRateCardId
									disabled
								}
							}
						}
					}
				}
			`,
		})
	)
);
