import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import * as amplitude from '@amplitude/analytics-browser';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/da';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/da';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import GenericModalConductor from '../forecast-app/shared/components/modals/generic_modal_conductor';
import {Toaster} from '../forecast-app/shared/components/toasts/another-toast/toaster';
import {GlobalLoader} from './global_loader';
import Router from './routerv2';
import {BrowserRouter} from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import localeDataEN from './../translations/lang/en.json';
import localeDataES from './../translations/lang/es.json';
import localeDataDA from './../translations/lang/da.json';
import localeDataFR from './../translations/lang/fr.json';
import localeDataFRUpdated from './../translations/lang/updated_fr.json';
import 'moment/locale/da';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import {setDefaultOptions} from 'date-fns';
import {enUS, enGB, fr, da, es} from 'date-fns/locale';
import Util from '../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../forecast-app/shared/util/FeatureUtil';
import {profileLocales, USER_LOCALE_STORAGE_KEY} from '../constants';
import {ForecastThemeProvider} from '../forecast-app/shared/ForecastThemeProvider';
require('../app.scss');

function dateFnsLocaleFromMomentLocale(momentLocale) {
	switch (momentLocale) {
		case 'en-US':
			return enUS;
		case 'en-GB':
			return enGB;
		case 'da':
			return da;
		case 'fr':
			return fr;
		case 'es':
			return es;
		default:
			return enUS;
	}
}

class Root extends Component {
	constructor(props) {
		super(props);
		const localStorageLocale = localStorage.getItem(USER_LOCALE_STORAGE_KEY);

		this.state = {
			openModals: [],
			fileModal: null,
			taskModal: null,
			locale: localStorageLocale ? localStorageLocale : 'en-US',
		};

		try {
			if (process.env.CIRCLE_BRANCH === 'production') {
				amplitude.init('3dd776b24763144b2feee32dc7954ea2', '', {minIdLength: 1}); // minIdLength set to circumvent the extremely odd Amplitude default to not allow ids shorter than 6 digits
			} else if (process.env.CIRCLE_BRANCH === 'master') {
				amplitude.init('0353fe15cd60513484112914cf4583cf', '', {minIdLength: 1});
			}
		} catch (e) {
			console.error(e);
		}
	}

	setLocale(locale) {
		if (hasFeatureFlag('static_day_data_generation')) {
			const profileLocale = profileLocales[locale];
			const {html_lang_attribute, formatting_locale, moment_locale} = profileLocale || profileLocales.ENGLISH_US;

			document.documentElement.setAttribute('lang', html_lang_attribute);
			moment.locale(moment_locale);
			setDefaultOptions({locale: dateFnsLocaleFromMomentLocale(moment_locale)});

			if (formatting_locale !== this.state.locale) {
				Util.localStorageSetItem(USER_LOCALE_STORAGE_KEY, formatting_locale);
				this.setState({locale: formatting_locale});
			}
		} else {
			let newLocale;
			if (locale === 'ENGLISH_US') {
				document.documentElement.setAttribute('lang', 'en-US');
				newLocale = 'en-US';
				moment.locale('en-US');
				setDefaultOptions({locale: enUS});
			} else if (locale === 'ENGLISH_UK') {
				document.documentElement.setAttribute('lang', 'en-GB');
				newLocale = 'en-GB';
				moment.locale('en-GB');
				setDefaultOptions({locale: enGB});
			} else if (locale === 'ENGLISH_EU') {
				document.documentElement.setAttribute('lang', 'en-150');
				newLocale = 'en-dk';
				moment.locale('en-GB');
				setDefaultOptions({locale: enGB});
			} else if (locale === 'SPANISH') {
				document.documentElement.setAttribute('lang', 'es');
				newLocale = 'es';
				moment.locale('es');
				setDefaultOptions({locale: es});
			} else if (locale === 'DANISH') {
				document.documentElement.setAttribute('lang', 'da');
				newLocale = 'da';
				moment.locale('da');
				setDefaultOptions({locale: da});
			} else if (locale === 'FRENCH') {
				document.documentElement.setAttribute('lang', 'fr');
				newLocale = 'fr';
				moment.locale('fr');
				setDefaultOptions({locale: fr});
			} else {
				// Default language
				document.documentElement.setAttribute('lang', 'en-US');
				newLocale = 'en-US';
				moment.locale('en-US');
				setDefaultOptions({locale: enUS});
			}
			if (newLocale !== this.state.locale) {
				Util.localStorageSetItem(USER_LOCALE_STORAGE_KEY, newLocale);
				this.setState({locale: newLocale});
			}
		}
	}

	/**
     Set the locale based on the browser language.
     Default is US English.
     **/
	setLocaleFromBrowser() {
		this.setLocale(this.getLanguageFromBrowser());
	}

	getLanguageFromBrowser() {
		var userLang = navigator.language || navigator.userLanguage;

		if (userLang === 'en-US') {
			return 'ENGLISH_US';
		} else if (userLang === 'en-GB') {
			return 'ENGLISH_UK';
		} else if (userLang === 'en-150') {
			return 'ENGLISH_EU';
		} else if (userLang === 'da' || userLang.startsWith('da-')) {
			return 'ENGLISH_EU'; //return 'DANISH';
		} else if (userLang === 'fr' || userLang.startsWith('fr-')) {
			return 'FRENCH';
		} else {
			return 'ENGLISH_US';
		}
	}

	getMessages() {
		switch (this.state.locale) {
			case 'en-US':
			case 'en-GB':
			case 'en-150':
			case 'en-dk':
				return localeDataEN;
			case 'es':
				return localeDataES;
			case 'da':
				return localeDataDA;
			case 'fr':
				return hasFeatureFlag('updated_french_translations') ? localeDataFRUpdated : localeDataFR;
			default:
				return localeDataEN;
		}
	}

	render() {
		const formats = {
			number: {
				always_two_decimal: {
					style: 'decimal',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				rounded_two_decimal: {
					style: 'decimal',
					maximumFractionDigits: 2,
				},
				rounded_one_decimal: {
					style: 'decimal',
					maximumFractionDigits: 1,
				},
				rounded_zero_decimal: {
					style: 'decimal',
					maximumFractionDigits: 0,
				},
			},
			relative: {
				numeric: {
					style: 'numeric',
				},
			},
		};
		return (
			<DndProvider backend={HTML5Backend}>
				<IntlProvider
					locale={this.state.locale}
					messages={this.getMessages()}
					formats={formats}
					defaultLocale="en"
					onError={err => {
						if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
							Sentry.captureException(new Error(err));
						} else {
							// eslint-disable-next-line no-console
							console.error(err);
						}
					}}
				>
					<ForecastThemeProvider>
						<ErrorBoundary showReloadButton={true}>
							<BrowserRouter
								getUserConfirmation={() => {
									/* Empty callback to block the default browser prompt when using useRouterPrompt */
								}}
							>
								<div data-userpilot="page">
									<GenericModalConductor />

									<Router
										viewerId={this.state.viewerId}
										setLocale={this.setLocale.bind(this)}
										setLocaleFromBrowser={this.setLocaleFromBrowser.bind(this)}
										newTaskFromLocationModal={this.state.newTaskFromLocationModal}
										taskModal={this.state.taskModal}
									/>

									<div id="root-portal-container" className="root-portal-container" />
									<Toaster />
									<GlobalLoader />
								</div>
							</BrowserRouter>
						</ErrorBoundary>
					</ForecastThemeProvider>
				</IntlProvider>
			</DndProvider>
		);
	}
}

export default Root;
